import {create} from "zustand";
import {Point} from "@/types/point";

export interface HistoryState {
    history: string[];
    addPath: (path: string) => void;
}

const useHistoryStore = create<HistoryState>((set, get) => ({
    history: [],
    addPath: path => {
        const history = get().history;
        if (history[history.length - 1] !== path) {
            set({history: [...history, path]});
        }
    },
}));

export default useHistoryStore;
