'use client'

import useHistoryStore from "@/stores/history.store";
import {usePathname} from "next/navigation";
import {useEffect} from "react";

export default function HistoryTracker() {
    const historyStore = useHistoryStore();
    const pathname = usePathname();

    useEffect(() => {
        historyStore.addPath(pathname);
    }, [pathname]);

    return null;
}
